import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "react-scroll";
import styled from "styled-components";
import { Container, Row, Col, Button } from "react-bootstrap";

import { theme } from "../../utils";
import { ModalVideoContext } from "../../contexts/ModalVideo";
import Section from "../../components/Section";
import imgMessenger from "../../assets/images/messanger.png";
import imgWhatsApp from "../../assets/images/whatsapp.png";

const SectionStyled = styled(Section)`
  background: ${theme.secondary};
  margin-top: -107px;
  padding: 120px 0 100px;
  @media (min-width: ${theme.breakpoints.md}px) {
    padding: 150px 0 150px;
  }
  @media (min-width: ${theme.breakpoints.lg}px) {
    padding: 150px 0 150px;
  }
`;

const ImgBannerBg = styled(Img)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const ContentContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  z-index: 20;
  overflow: hidden;
`;

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImgBanner = styled(Img)`
  width: 100%;
  max-width: 90%;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    font-size: 45px;
    line-height: 52px;
    text-align: left;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    font-size: 20px;
    line-height: 35px;
    text-align: left;
  }
`;

const CTAText = styled.h3`
  color: #fff;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    font-size: 30px;
    line-height: 35px;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }
`;

const Hero = () => {
  const modalVideo = useContext(ModalVideoContext);

  const {
    datoCmsLanding,
    datoCmsSocial,
    bannerBg,
    bannerImg,
  } = useStaticQuery(graphql`
    query {
      bannerBg: file(relativePath: { eq: "bannerBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 10) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      bannerImg: file(relativePath: { eq: "bannerImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      datoCmsLanding {
        bannerTitle
        bannerText
        bannerCtaText
      }
      datoCmsSocial {
        messenger
        whatsapp
      }
    }
  `);

  return (
    <>
      <SectionStyled className="position-relative">
        {/* <DiagonalContainerBackground /> */}
        <ImgBannerBg
          fluid={bannerBg.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
        <ContentContainer>
          <Container>
            <Row>
              <Col lg="6">
                <div className="h-100 d-flex flex-column justify-content-center">
                  <div>
                    <Title
                      className="mb-3 mb-lg-4"
                      className="text-left text-md-center text-lg-left"
                    >
                      {datoCmsLanding.bannerTitle}
                    </Title>
                    <Text className="text-left text-md-center text-lg-left">
                      {datoCmsLanding.bannerText}
                    </Text>
                  </div>
                  <div className="mt-4 mb-3 my-lg-5">
                    <ButtonContainer>
                      <div>
                        <Button
                          size="lg"
                          onClick={modalVideo.openModal}
                          className="mr-lg-3 mb-3 mb-lg-0"
                        >
                          ¡Mira como Funciona!
                        </Button>
                      </div>
                      <div>
                        <Button
                          as={Link}
                          variant="secondary"
                          size="lg"
                          to="pricing"
                          className="text-white"
                          spy={true}
                          smooth={true}
                        >
                          Precios y Planes
                        </Button>
                      </div>
                    </ButtonContainer>
                  </div>
                  <div className="d-flex justify-content-center justify-content-lg-start align-items-center">
                    <CTAText>{datoCmsLanding.bannerCtaText}:</CTAText>
                    <a
                      href={datoCmsSocial.messenger}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="mx-3"
                        src={imgMessenger}
                        alt=""
                        css={`
                          width: 40px;
                        `}
                      />
                    </a>
                    <a
                      href={datoCmsSocial.whatsapp}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={imgWhatsApp}
                        alt=""
                        css={`
                          width: 40px;
                        `}
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <ImgContainer>
                  <ImgBanner fluid={bannerImg.childImageSharp.fluid} alt="" />
                </ImgContainer>
              </Col>
            </Row>
          </Container>
        </ContentContainer>
      </SectionStyled>
    </>
  );
};

export default Hero;
