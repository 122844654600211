import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { theme } from "../../utils";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import SectionSubtitle from "../../components/SectionSubtitle";

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImgInner = styled(Img)`
  width: 100%;
  max-width: 90%;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    max-width: 100%;
  }
`;

const Features = (props) => {
  const { datoCmsLanding, imgF1, imgF2, imgF3 } = useStaticQuery(graphql`
    query {
      imgF1: file(relativePath: { eq: "1of3.png" }) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 10) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgF2: file(relativePath: { eq: "2of3.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgF3: file(relativePath: { eq: "3of3.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      datoCmsLanding {
        featureTitle
        featureText
        featureItems {
          textBold
          textNormal
          textPrimary
        }
      }
    }
  `);

  return (
    <>
      <Section {...props}>
        <Container>
          <SectionTitle className="text-center">
            {datoCmsLanding.featureTitle}
          </SectionTitle>
          <SectionSubtitle className="text-center pt-2 mb-5 ">
            {datoCmsLanding.featureText}
          </SectionSubtitle>
          {datoCmsLanding.featureItems.map((item, index) => (
            <Row
              className={`mb-4 mb-lg-0 ${
                index % 2 !== 0
                  ? "d-flex flex-column-reverse flex-lg-row-reverse"
                  : "d-flex flex-column-reverse flex-lg-row flex-lg-row"
              }`}
              key={index}
            >
              <Col lg="6">
                <div className="d-lg-flex flex-lg-column justify-content-center h-100 mt-4 mt-lg-0">
                  <h5 className="font-weight-bold text-center text-lg-left mb-3">
                    {item.textBold}
                  </h5>
                  <p className="text-center text-lg-left mb-3">
                    {item.textNormal}
                  </p>
                  <p className="font-weight-bold text-primary text-center text-lg-left">
                    {item.textPrimary}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <ImgContainer>
                  <ImgInner
                    fluid={
                      index === 0
                        ? imgF1.childImageSharp.fluid
                        : index === 1
                        ? imgF2.childImageSharp.fluid
                        : imgF3.childImageSharp.fluid
                    }
                    alt=""
                  />
                </ImgContainer>
              </Col>
            </Row>
          ))}
        </Container>
      </Section>
    </>
  );
};

export default Features;
