import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { theme } from "../../utils";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import SectionSubtitle from "../../components/SectionSubtitle";

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 0;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImgInner = styled(Img)`
  width: 100%;
  max-width: 90%;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    max-width: 100%;
  }
`;

const Power = () => {
  const { datoCmsLanding, imgPower1, imgPower2 } = useStaticQuery(graphql`
    query {
      imgPower1: file(relativePath: { eq: "power1.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgPower2: file(relativePath: { eq: "power2.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      datoCmsLanding {
        powerText
        powerTitle
        powerItems {
          power3
          power2
          power1
          title
        }
      }
    }
  `);

  return (
    <>
      <Section className="position-relative">
        <Container>
          <SectionTitle className="text-center mb-4">
            {datoCmsLanding.powerTitle}
          </SectionTitle>
          <p className="font-weight-bold text-center pb-lg-5">
            {datoCmsLanding.powerText}
          </p>
          {datoCmsLanding.powerItems.map((item, index) => (
            <Row
              className={
                index === 0 ? "flex-column flex-lg-row-reverse mt-5" : ""
              }
              key={index}
            >
              <Col lg="6">
                <ImgContainer
                  className={index === 1 ? "d-flex justify-content-lg-end" : ""}
                >
                  <ImgInner
                    fluid={
                      index === 0
                        ? imgPower1.childImageSharp.fluid
                        : imgPower2.childImageSharp.fluid
                    }
                    alt=""
                  />
                </ImgContainer>
              </Col>
              <Col lg="6">
                <div
                  className={`d-flex flex-column justify-content-center h-100 ${
                    index === 0 ? "mb-5 mb-lg-0" : ""
                  }`}
                >
                  <h5 className="mt-4 mt-lg-0 mb-4">{item.title}</h5>

                  <div className="d-flex mb-4">
                    <div>
                      <div
                        className="border border-info d-flex align-items-center justify-content-center mr-3"
                        css={`
                          border-radius: 50%;
                          width: 20px;
                          height: 20px;
                        `}
                      >
                        <small>1</small>
                      </div>
                    </div>

                    <div
                      css={`
                        margin-top: -0.25rem;
                      `}
                    >
                      {item.power1}
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div>
                      <div
                        className="border border-info d-flex align-items-center justify-content-center mr-3"
                        css={`
                          border-radius: 50%;
                          width: 20px;
                          height: 20px;
                        `}
                      >
                        <small>2</small>
                      </div>
                    </div>

                    <div
                      css={`
                        margin-top: -0.25rem;
                      `}
                    >
                      {item.power2}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <div
                        className="border border-info d-flex align-items-center justify-content-center mr-3"
                        css={`
                          border-radius: 50%;
                          width: 20px;
                          height: 20px;
                        `}
                      >
                        <small>3</small>
                      </div>
                    </div>

                    <div
                      css={`
                        margin-top: -0.25rem;
                      `}
                    >
                      {item.power3}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </Section>
    </>
  );
};

export default Power;
