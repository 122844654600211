import styled from "styled-components";
import { theme } from "../../utils";

const SectionSubtitle = styled.h4`
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    text-align: left;
    font-size: 26px;
    line-height: 35px;
  }

  text-align: center;
  font-size: 23px;
  line-height: 30px;
`;

export default SectionSubtitle;
