import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { theme } from "../../utils";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import SectionSubtitle from "../../components/SectionSubtitle";

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImgInner = styled(Img)`
  width: 100px;
  height: auto;
  max-width: 90%;
  display: none;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    width: 120px;
    max-width: initial;
    display: block;
  }
`;

const Features = (props) => {
  const { datoCmsLanding, robotImg, imgM1, imgM2 } = useStaticQuery(graphql`
    query {
      robotImg: file(relativePath: { eq: "magicRobot.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgM1: file(relativePath: { eq: "magic1.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgM2: file(relativePath: { eq: "magic2.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      datoCmsLanding {
        magicText
        magicTitle
        magicItems {
          title
          text
        }
      }
    }
  `);

  return (
    <>
      <Section {...props}>
        <Container>
          <SectionTitle className="text-center">
            {datoCmsLanding.magicTitle}
          </SectionTitle>
          <SectionSubtitle className="text-center pt-4 pb-lg-5">
            {datoCmsLanding.magicText}
          </SectionSubtitle>
          <Row>
            {datoCmsLanding.magicItems.map((item, index) => (
              <Col lg={index === 0 ? "5" : "7"} key={index}>
                <div className="d-flex">
                  {index === 1 && (
                    <ImgContainer className="mr-lg-3">
                      <ImgInner fluid={robotImg.childImageSharp.fluid} alt="" />
                    </ImgContainer>
                  )}

                  <div className="d-lg-flex flex-lg-column justify-content-center h-100 ">
                    <div className="d-flex justify-content-center justify-content-lg-start mt-5 mt-lg-0">
                      <Img
                        fluid={
                          index === 0
                            ? imgM1.childImageSharp.fluid
                            : imgM2.childImageSharp.fluid
                        }
                        css={`
                          width: 80px;
                        `}
                        alt=""
                      />
                    </div>
                    <h5 className="font-weight-bold text-center text-lg-left my-4">
                      {item.title}
                    </h5>
                    <p className="text-center text-lg-left mb-0">{item.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Features;
