import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { theme } from "../../utils";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import imgCheck from "../../assets/images/check.png";

const ContentContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  z-index: 20;
`;

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 0;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImgInner = styled(Img)`
  width: 100%;
  max-width: 90%;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    max-width: 100%;
  }
`;

const Benefit = () => {
  const { datoCmsLanding, benefitImg, logoImg } = useStaticQuery(graphql`
    query {
      benefitImg: file(relativePath: { eq: "benefitImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logoImg: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      datoCmsLanding {
        benefitText
        benefitTitle
        benefitItems {
          title
        }
      }
    }
  `);

  return (
    <>
      <Section className="position-relative">
        <ContentContainer>
          <Container>
            <Row>
              <Col lg="6">
                <ImgContainer>
                  <ImgInner fluid={benefitImg.childImageSharp.fluid} alt="" />
                </ImgContainer>
              </Col>

              <Col lg="6">
                <div>
                  <SectionTitle className="mt-5 mt-lg-0 mb-4">
                    {datoCmsLanding.benefitTitle}
                  </SectionTitle>
                  <p className="font-weight-bold mb-3 text-center text-lg-left">
                    {datoCmsLanding.benefitText}
                  </p>
                  {datoCmsLanding.benefitItems.map((item, index) => (
                    <div className="d-flex align-items-center mb-3" key={index}>
                      <img
                        src={imgCheck}
                        css={`
                          width: 20px;
                          height: 18px;
                        `}
                        alt=""
                        className="mr-2"
                      />
                      <div>{item.title}</div>
                    </div>
                  ))}

                  <div className="mt-5">
                    <Img
                      css={`
                        width: 400px;
                        max-width: 90%;
                      `}
                      fluid={logoImg.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </ContentContainer>
      </Section>
    </>
  );
};

export default Benefit;
