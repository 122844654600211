import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Row, Col, Button } from "react-bootstrap";

import { theme } from "../../utils";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import SectionSubtitle from "../../components/SectionSubtitle";

import imgCheck from "../../assets/images/check-pricing.png";

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImgInner = styled(Img)`
  width: 130px;
  height: auto;
  max-width: 90%;
`;

const Pricing = () => {
  const {
    datoCmsLanding,
    allDatoCmsPricing,
    imgP1,
    imgP2,
    imgP3,
  } = useStaticQuery(graphql`
    query {
      datoCmsLanding {
        pricingText
        pricingTitle
      }
      imgP1: file(relativePath: { eq: "pricing1.png" }) {
        childImageSharp {
          fluid(maxWidth: 135) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgP2: file(relativePath: { eq: "pricing2.png" }) {
        childImageSharp {
          fluid(maxWidth: 135) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imgP3: file(relativePath: { eq: "pricing3.png" }) {
        childImageSharp {
          fluid(maxWidth: 135) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      allDatoCmsPricing {
        nodes {
          pricingItems {
            title
          }
          title
          subTitle
          pricingMonthly
          pricingImplementation
          link
        }
      }
    }
  `);

  const pricings = allDatoCmsPricing.nodes;

  return (
    <>
      <Section className="position-relative">
        <Container>
          <SectionTitle className="text-center mb-4">
            {datoCmsLanding.pricingTitle}
          </SectionTitle>
          <p className="font-weight-bold text-center pb-5">
            {datoCmsLanding.pricingText}
          </p>
          <Row>
            {pricings.map((item, index) => (
              <Col
                lg="4"
                className={index !== 2 ? "mb-5 mb-lg-0" : ""}
                key={index}
              >
                <div
                  className={`d-lg-flex flex-lg-column justify-content-center h-100 p-4 rounded border ${
                    index === 1 ? "shadow" : ""
                  }`}
                  css={`
                    transform: ${index === 1 ? "scale(1.05)" : "initial"};
                  `}
                >
                  <ImgContainer className="d-flex flex-column align-items-center justify-content-center mb-4">
                    <ImgInner
                      fluid={
                        index === 0
                          ? imgP1.childImageSharp.fluid
                          : index === 1
                          ? imgP2.childImageSharp.fluid
                          : imgP3.childImageSharp.fluid
                      }
                      alt=""
                    />

                    <SectionSubtitle className="text-primary text-center my-3">
                      {item.title}
                    </SectionSubtitle>
                    <h4
                      className="text-center mb-3"
                      css={`
                        font-size: 17px;
                      `}
                    >
                      {item.subTitle}
                    </h4>
                  </ImgContainer>

                  {item.pricingItems.map((item, index) => (
                    <div className="d-flex align-items-center mb-3" key={index}>
                      <img
                        src={imgCheck}
                        css={`
                          width: 15px;
                          height: 13px;
                        `}
                        alt=""
                        className="mr-2"
                      />
                      <small>{item.title}</small>
                    </div>
                  ))}

                  <div className="d-flex flex-column align-items-center justify-content-center my-4">
                    <SectionSubtitle className="text-primary text-center my-3">
                      ${item.pricingMonthly} /mes
                    </SectionSubtitle>
                    <h4
                      className="text-center mb-3"
                      css={`
                        font-size: 17px;
                      `}
                    >
                      ${item.pricingImplementation} /Implementación
                    </h4>

                    <Button
                      as="a"
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        index === 1
                          ? `btn btn-primary btn-lg mt-3`
                          : `btn btn-outline-dark mt-3`
                      }
                      variant="primary"
                      css={`
                        border-radius: 1.5rem !important;
                      `}
                    >
                      Empezar
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Pricing;
