import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Col, Form, Button } from "react-bootstrap";

import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";

const Contact = () => {
  const { datoCmsLanding } = useStaticQuery(graphql`
    query {
      datoCmsLanding {
        contactTitle
        contactTextBold
        contactText
      }
    }
  `);
  return (
    <>
      <Section className="position-relative">
        <Container>
          <SectionTitle className="text-center mb-4">
            {datoCmsLanding.contactTitle}
          </SectionTitle>
          <p className="font-weight-bold text-center">
            {datoCmsLanding.contactTextBold}
          </p>
          <p className="text-center pb-5">{datoCmsLanding.contactText}</p>
          <Form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <Form.Row>
              <Form.Group as={Col} controlId="formGridName" lg="6">
                <Form.Control
                  type="text"
                  placeholder="Tú Nombre"
                  size="lg"
                  name="name"
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail" lg="6">
                <Form.Control
                  type="email"
                  placeholder="Tú correo electrónico"
                  size="lg"
                  name="email"
                  required
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control
                as="textarea"
                rows="4"
                placeholder="Cuéntanos de tu proyecto"
                size="lg"
                name="message"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" size="lg">
              ENVIAR
            </Button>
          </Form>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
