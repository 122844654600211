import styled from "styled-components";
import { theme } from "../../utils";

const SectionTitle = styled.h2`
  @media only screen and (min-width: ${theme.breakpoints.lg}px) {
    text-align: left;
    font-size: 36px;
    line-height: 45px;
  }

  text-align: center;
  font-size: 28px;
  line-height: 35px;
`;

export default SectionTitle;
