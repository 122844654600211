import React from "react";
import { Element as ScrollElement } from "react-scroll";

import Hero from "../sections/Hero";
import Benefit from "../sections/Benefit";
import Features from "../sections/Features";
import Magic from "../sections/Magic";
import Power from "../sections/Power";
import Pricing from "../sections/Pricing";
import Contact from "../sections/Contact";

const IndexPage = () => {
  return (
    <>
      <ScrollElement name="intro">
        <Hero />
      </ScrollElement>

      <ScrollElement name="benefit">
        <Benefit />
      </ScrollElement>

      <Features />

      <Magic />

      <Power />

      <ScrollElement name="pricing">
        <Pricing />
      </ScrollElement>

      <ScrollElement name="contact">
        <Contact />
      </ScrollElement>
    </>
  );
};
export default IndexPage;
